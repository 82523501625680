<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="row">
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";

export default {
  name: 'ProductPropertyAdd',
  components: {
    BreadcrumbHeader,
  },
  computed: {
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Atrybuty', link: {name: 'product-property-list'}},
        {title: 'Nowy atrybut'}
      ];
    },
  },
};
</script>
